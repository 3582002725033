<template>
  <div class="flex flex-col">
    <projects @goToAddProject="goToAddProject"></projects>
  </div>
</template>

<script>
import Projects from "@/components/dashboard/overview/Projects";
export default {
  components: {
    Projects
  },
  methods: {
    goToAddProject() {
      this.$emit("goToAddProject");
    }
  }
};
</script>
