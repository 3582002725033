<template>
  <router-link :to="'/projects/' + data.uuid">
    <draft-description :data="data"></draft-description>
  </router-link>
</template>

<script>
import DraftDescription from "@/components/reusable/DraftDescription";
export default {
  components: { DraftDescription },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isFirst: Boolean
  }
};
</script>
