<template>
  <projects></projects>
</template>

<script>
import Projects from "@/components/company/Projects";
export default {
  components: {
    Projects
  }
};
</script>
