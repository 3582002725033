<template>
  <div
    class="flex flex-col bg-white"
    :class="draftsLoaded && drafts.results.length === 0 ? 'h-full' : ''"
  >
    <div class="flex items-center pb-1 mobile:justify-between mobile:flex-wrap">
      <div
        class="flex mobile:mb-3 mobile:w-full mobile:flex-col mobile:items-center"
      >
        <h2 class="mr-4 mobile:mr-0 mobile:mb-2">Minu projektid</h2>
        <router-link
          to="/projects/new"
          v-if="
            ['R0', 'R1', 'R2'].includes(
              $store.state.companyData.activeCompany.worker_permission
            )
          "
          class="mr-4 mobile:mr-0"
        >
          <button class="alt-button-green">
            <span class="typcn typcn-plus icon"></span>
            <span class="label">Lisa projekt</span>
          </button>
        </router-link>
      </div>

      <div
        class="flex relative mobile:justify-center items-center justify-center mobile:pb-2 mobile:self-center mobile:w-full"
      >
        <div class="flex items-center justify-center w-full mobile:self-center">
          <div
            class="items-center flex flex-row cursor-pointer hover:bg-offwhite hover:bg-opacity-50 duration-50 relative"
            @click="menuActive = !menuActive"
            :class="menuActive ? 'rounded-t-xl' : 'rounded-xl'"
          >
            <span
              v-if="statusFilter"
              v-html="parseProjectStatus(statusFilter.status)"
            />
            <span v-if="!statusFilter" class="pl-4 pr-8 py-2"
              >Filtreeri staatuse järgi</span
            >
            <span class="typcn typcn-arrow-sorted-down mx-3"></span>
            <div class="status-menu" v-if="menuActive">
              <span
                v-for="status in statusFilters"
                :key="status.status"
                class="status-menu-item cursor-pointer"
                v-html="parseProjectStatus(status.status)"
                @click="selectStatus(status)"
              ></span>
            </div>
          </div>

          <div class="ml-2">
            <button
              class="new-button-danger"
              v-if="statusFilter"
              @click="selectStatus(null)"
            >
              <span class="icon"
                ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
              /></span>
              <span class="label">Tühjenda</span>
            </button>
          </div>
        </div>
      </div>

      <div
        class="ml-auto mobile:ml-0 mobile:w-full flex items-center bg-offwhite rounded-full duration-100 mobile:mb-3"
        :class="focusSearch ? 'shadow-outline' : 'shadow'"
      >
        <div class="w-1/12 px-1 items-center justify-center flex">
          <span class="">
            <img src="/bc21/search.svg" class="h-4 w-4" />
          </span>
        </div>
        <input
          type="text"
          v-model="searchQuery"
          @input="debounceSearch"
          placeholder="Otsi projekti"
          class="shadow-none focus:shadow-none hover:shadow-none rounded-full w-11/12"
          @focus="focusSearch = true"
          @blur="focusSearch = false"
        />
      </div>
    </div>

    <div
      class="flex flex-col w-full bg-white py-2"
      v-if="draftsLoaded"
      :class="drafts.results.length === 0 ? 'h-full' : ''"
    >
      <div
        v-if="
          drafts.results.length === 0 &&
            $store.state.companyData.companies.length > 0
        "
        class="mx-auto my-auto py-2 px-8 flex flex-col justify-center items-center"
      >
        <div class="flex justify-center mb-4">
          <img src="/favicon.ico" class="h-24 w-24" />
        </div>
        <h3 class="mb-2 flex mt-auto">Aktiivseid projekte pole</h3>
        <div class="flex">
          <router-link to="/projects/new">
            <button class="new-button-green">
              <span class="typcn typcn-plus icon"></span>
              <span class="label">Lisa uus projekt</span>
            </button>
          </router-link>
        </div>
      </div>
      <span
        v-if="
          drafts.results.length === 0 &&
            $store.state.companyData.companies.length === 0
        "
        class="mb-2"
      >
        Projektide loomiseks ja Gewodo ärihalduse tööriistade kasutamiseks
        vajuta
        <router-link to="/company/new" class="font-medium hover:text-green"
          >siia</router-link
        >.</span
      >
      <draft
        v-for="(item, index) in drafts.results"
        :key="item.uuid"
        :data="item"
        :is-first="index === 0"
      ></draft>
      <paginator
        class="self-center mt-4"
        v-if="drafts.count > 1"
        :page="page"
        :count="drafts.count"
        @pageChange="pageChange"
      ></paginator>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import Draft from "./projects/Draft";
import Paginator from "@/components/reusable/Paginator";
import debounce from "lodash/debounce";
import StatusParser from "@/assets/mixins/StatusParser";

const statusFilters = [
  {
    text: "Ootel",
    status: 1
  },
  {
    text: "Aktiivne",
    status: 2
  },
  {
    text: "Käigus",
    status: 3
  },
  {
    text: "Probleem",
    status: 4
  },
  {
    text: "Lõpetatud",
    status: 5
  },
  {
    text: "Kinnitatud",
    status: 6
  }
];
export default {
  data() {
    return {
      drafts: null,
      draftsLoaded: false,
      page: 1,
      focusSearch: false,
      searchQuery: "",
      statusFilter: null,
      statusFilters: [],
      menuActive: false
    };
  },
  components: {
    Paginator,
    Draft
  },
  mixins: [RequestHandler, StatusParser],
  mounted() {
    this.statusFilters = statusFilters;
    this.retrieveDrafts();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.retrieveDrafts();
    },
    selectStatus(status) {
      this.statusFilter = status;
      this.menuActive = false;
      this.retrieveDrafts();
    },
    retrieveDrafts() {
      this.draftsLoaded = false;
      this.apiRequest(
        "notes/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/drafts/?page=" +
          this.page +
          (this.searchQuery ? "&s=" + this.searchQuery : "") +
          (this.statusFilter ? "&state=" + this.statusFilter.status : ""),
        "get",
        true
      ).then(res => {
        this.draftsLoaded = false;
        this.drafts = res.data;
        this.draftsLoaded = true;
      });
    },
    openAddProject() {
      this.$emit("goToAddProject");
    },
    debounceSearch: debounce(function() {
      this.retrieveDrafts();
    }, 500)
  }
};
</script>
